var connectApplication = null;
var CONNECT_INSTALLER = '//d3gcli72yxqn2z.cloudfront.net/connect/v4';
var minConnect = '3.6.0'; // pick up the protocol and use that to download the latest connect files
// however if we are running off the local file system use http

var currentProto;

if (window.location.protocol.indexOf('file') != -1) {
  currentProto = 'http:';
} else {
  currentProto = window.location.protocol;
}

var installerPath = currentProto + CONNECT_INSTALLER;
var installersLoaded = 0;
$.getScript(installerPath + '/asperaweb-4.js', function (data, textStatus, jqxhr) {
  checkInstallersLoaded();
});
$.getScript(installerPath + '/connectversions.js', function (data, textStatus, jqxhr) {
  checkInstallersLoaded();
});
$.getScript(installerPath + '/connectinstaller-4.js', function (data, textStatus, jqxhr) {
  checkInstallersLoaded();
}); // before initAsperaConnect make sure all three installers have been loaded

var checkInstallersLoaded = function checkInstallersLoaded() {
  installersLoaded++;

  if (installersLoaded == 3) {
    initAsperaConnect();
  }
};

var handleConnectReady = function handleConnectReady() {
  // Called if Aspera Connect is installed and meets version requirements.
  setupConnectApplication();
  setup();
};

var handleInstallError = function handleInstallError() {// Called if an install error occurs. Display some text.
}; // Please note in a realistic real world environment you would supply a minVersion param with the startEmbeddedInstall
// The way done here will prompt for install every time (if a newer version is available), if the install is cancelled the app will still work
// if the user meets the minVersion. The way done with minVersion in start embedded install will only call an install
// if the minVersion is not met.


var handleInstallDismiss = function handleInstallDismiss() {
  //Called if install is dismissed by the user, used in connect 3.0 onwards
  setupConnectApplication();
  var connectApp = connectApplication.version().connect;
  var connectPlugin = connectApplication.version().plugin;

  if (connectApp.installed && connectPlugin.installed) {
    //  A version of connect is already installed, make sure it is not above our min version.
    if (versionComparison(connectApp.version, minConnect) || versionComparison(connectPlugin.version, minConnect)) {
      disableApp('This application requires a higher version of Aspera connect (' + minVersion + '). Please upgrade in order to use this application.');
    } else {
      // Everything is good, continue to running code
      handleConnectReady();
    }
  } else {
    // Connect is not installed
    disableApp('This application requires Aspera connect. Please install Aspera connect in order to use this application.');
  }
}; // eslint-disable-next-line


var handleInstall = function handleInstall() {
  // Called if an install is required.
  window.connectInstaller.startEmbeddedInstall({
    installError: handleInstallError,
    stylesheet: currentProto + '//d2fvxkmjao6pcr.cloudfront.net/custom.css',
    installDismiss: handleInstallDismiss,
    prompt: true // in a real world example you would supply minVersion here (installed of the check on handleInstallDimiss)

  });
};

var initAsperaConnect = function initAsperaConnect() {
  /* This SDK location should be an absolute path, it is a bit tricky since the usage examples
   * and the install examples are both two levels down the SDK, that's why everything works
   */
  var asperaInstaller = new AW4.ConnectInstaller({
    sdkLocation: CONNECT_INSTALLER
  }); // eslint-disable-next-line

  var statusEventListener = function statusEventListener(eventType, data) {
    if (eventType === AW4.Connect.EVENT.STATUS && data == AW4.Connect.STATUS.INITIALIZING) {
      asperaInstaller.showLaunching();
    } else if (eventType === AW4.Connect.EVENT.STATUS && data == AW4.Connect.STATUS.FAILED) {
      asperaInstaller.showDownload();
    } else if (eventType === AW4.Connect.EVENT.STATUS && data == AW4.Connect.STATUS.OUTDATED) {
      asperaInstaller.showUpdate();
    } else if (eventType === AW4.Connect.EVENT.STATUS && data == AW4.Connect.STATUS.RUNNING) {
      asperaInstaller.connected();
    }
  };
}; // Helper function to check the version
// Assumes valid parameters will be passed i.e. 2.8.5 etc.


function versionComparison(verCur, verMin) {
  if (verCur === undefined || verMin === undefined) {
    return false;
  }

  verCurSplit = verCur.split('.');
  verMinSplit = verMin.split('.');
  var less;

  if (verCurSplit[0] < verMinSplit[0]) {
    // Version is less
    less = true;
  } else if (verCurSplit[0] == verMinSplit[0]) {
    if (verCurSplit.length > 1 && verMinSplit.length > 1) {
      // the array has another number we can look at, lets do it (remember to pass the string tho)
      less = versionComparison(verCur.slice(2), verMin.slice(2));
    } else {
      // we are assuming they are equal or the user does not care, return false
      less = false;
    }
  } else {
    // version is not less
    less = false;
  }

  return less;
}

function setupConnectApplication() {
  if (connectApplication === null) {
    connectApplication = new AW4.Connect({
      id: 'aspera_web_transfers',
      sdkLocation: CONNECT_INSTALLER,
      minVersion: '3.6.0'
    });
  }
} // Helper function


function disableApp(message) {
  document.body.innerHTML = '<h1 style="text-align:center;">' + document.title + '</h1><p>' + message + '</p><p><a href="#" onclick="javascript:location.reload();return false;">Restart Connect Installer</a></p>';
}